import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  //Badge,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";
//import { getAuthorization } from "webauthn/session";

const ListCards = () => {
  const history = useHistory();

  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const [cards, setCards] = useState([]);

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.legal_entity_uuid) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/payment-card`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );

        if (response.ok) {
          const json = await response.json();
          setCards(json.cards);
        }
      }
    })();
  }, [setCards]);

  const applyNow = async () => {
    /*const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/payment-account/apply`,
      {
        headers: {
          Authorization: await getAuthorization(),
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({}),
      }
    );

    if (response.ok) {
      alert("A team member will reach out shortly to open your account!");
    }*/
    alert("Coming soon!");
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Cards</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Card Account Name</th>
                    <th scope="col">Last 4</th>
                    <th scope="col">Expiry</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {cards.length === 0 ? (
                    <tr>
                      <th colSpan="3">
                        <h3 className="text-center">
                          Apply for an accountedfor Mastercard
                        </h3>
                        <p className="text-center">
                          An accountedfor prepaid business Mastercard will take
                          your business spending to the next level of efficiency
                        </p>
                        <p className="text-center">
                          <Button color="success" onClick={() => applyNow()}>
                            Apply Now
                          </Button>
                        </p>
                      </th>
                    </tr>
                  ) : undefined}
                  {cards.map((card, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">
                          {card.account_name}
                          <span className="ml-2">
                            {card.sandbox ? (
                              <Badge color="warning" pill>
                                Sandbox
                              </Badge>
                            ) : undefined}
                          </span>
                        </th>
                        <td>•••• {card.last_4}</td>
                        <td>{card.expiry}</td>
                        <td className="text-center" width="200px">
                          <Button
                            color="success"
                            onClick={() =>
                              history.push(
                                `/dashboard/cards/${card.uuid}`
                              )
                            }
                            size="sm"
                          >
                            View Transactions
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListCards;
