import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Badge,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";
//import { getAuthorization } from "webauthn/session";

const CardDetail = (props) => {
    const params = useParams();
  const history = useHistory();

  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.legal_entity_uuid) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/payment-card/${params.uuid}/transaction`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );

        if (response.ok) {
          const json = await response.json();
          setTransactions(json.transactions);
        }
      }
    })();
  }, [setTransactions]);

  const applyNow = async () => {
    /*const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/payment-account/apply`,
      {
        headers: {
          Authorization: await getAuthorization(),
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({}),
      }
    );

    if (response.ok) {
      alert("A team member will reach out shortly to open your account!");
    }*/
    alert("Coming soon!");
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Card Transactions</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((transaction) => {
                    return (
                      <tr key={transaction.id}>
                        <th scope="row">
                          {DateTime.fromISO(
                            transaction.cleared_date || transaction.created_at
                          ).toLocaleString(DateTime.DATE_MED)}
                        </th>
                        <td>
                          {transaction.description ||
                            transaction.data?.line_items?.[0]?.description}
                        </td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            {transaction.cleared_date === null ? (
                              <>
                                <i className="bg-info" />
                                pending
                              </>
                            ) : (
                              <>
                                <i className="bg-success" />
                                cleared
                              </>
                            )}
                          </Badge>
                        </td>
                        <td className="text-right">
                          {new Intl.NumberFormat("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          }).format(transaction.amount)}{" "}
                          {transaction.currency}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CardDetail;
